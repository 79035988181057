.clearfix {
    clear: both;
}

#welcomeText {
    float: left;
    font-size: revert;
}

#logout{
    float: right;
}

#header {
    margin-bottom: 50px;
}